import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import theme from '../theme';

interface Props {
  link?: string
  title?: string
  date?: string
}

export function ListItem({ link, title, date }: Props) {
	return (
		<ListItemContainer>
			<Link to={link} itemProp="url">
				<span itemProp="headline">{title}</span>
				<span itemProp="data" className="date">{date}</span>
			</Link>
		</ListItemContainer>
	)
}
export default ListItem;

const ListItemContainer = styled.div`
border-radius:6px;
padding:0px 8px;
margin-left:-8px;
margin-right:-8px;
  &:hover {
    background:${theme.colors.hoverHighlight};
  }

  .date {
    display:inline-block;
    width:80px;
    margin-right:16px;
    text-align:left;
    font-family:Monaco, monospace;
    font-weight:400;
    font-size:14px;
    opacity:0.5;
    margin-left:16px;
    margin-top:-4px;
  }

  a {
    text-decoration:none;

    &:hover {
    text-decoration:underline;
  }
`;
