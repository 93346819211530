import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Block from '../components/Block';
import ListItem from '../components/ListItem';

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || 'Title';
	const posts = data.allMarkdownRemark.nodes;

	return (
		<Layout location={location} title={siteTitle}>
			<Block>
				<h1>All posts</h1>
				<ol style={{ listStyle: 'none' }}>
					{posts.map((post) => {
						const title = post.frontmatter.title || post.fields.slug;

						return (
							<li key={post.fields.slug}>
								<ListItem title={title} link={post.fields.slug} date={post.frontmatter.date} ></ListItem>
							</li>
						);
					})}
				</ol>
			</Block>
		</Layout>
	);
};

export default BlogIndex;

const WritingContainer = styled.div`

`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
			title
			date(formatString: "MMM D")
			description
			tags
			author
        }
      }
    }
  }
`;
